import React, { useState, useEffect  } from 'react';
import { Section } from './section';
import { projects1, projects2 } from '../../assets/data/projectsData';
import { Tilt } from 'react-tilt';
import internetIcon from '../../assets/socialIcons/internet.png';
import githubIcon from '../../assets/socialIcons/github.png';
import githubIconBlue from '../../assets/socialIcons/github-blue.png';

const defaultOptions = {
  reverse:        false,  // reverse the tilt direction
  max:            30,     // max tilt rotation (degrees)
  perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
  scale:          1,    // 2 = 200%, 1.5 = 150%, etc..
  speed:          1000,   // Speed of the enter/exit transition
  transition:     true,   // Set a transition on enter/exit.
  axis:           null,   // What axis should be disabled. Can be X or Y.
  reset:          true,    // If the tilt effect has to be reset on exit.
  easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}

const ProjectsSection = ({ currentSection, screenWidth }) => {

  return (
    <Section id="projects__section">
      <div className="projects">
        <div className="bubble_wrap projects__header">
          <div className="section__text section__text_normal fz-header">Projects_</div>
        </div>
        <div className="bubble_wrap bubble_wrap_projects">
          <p className='fz-text'>
            {screenWidth < 550 ? "All my projects you can find on my " : "This is some of my projects. All my projects you can find on my "}
            <a href="https://github.com/anackis?tab=repositories">Github</a>
          </p>
          {/* <p className='fz-text'>
            This is some of my projects. All my projects you can find on my <a href="https://github.com/anackis?tab=repositories">Github</a> public page.
          </p> */}
          {/* <p className='fz-text'>
            All my projects you can find on my <a href="https://github.com/anackis?tab=repositories">Github</a>
          </p> */}
        </div>
        <TabComponent section={currentSection}/>
      </div>
      <div className="area">
        <ul className="circles">
          <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
      </div>
    </Section>
  )
}

const TabComponent = ({ section }) => {
  const [activeTab, setActiveTab] = useState('Tab1');
  const [loading, setLoading] = useState(true);
  const [transitionStage, setTransitionStage] = useState('fadeIn');
  const [currentPage, setCurrentPage] = useState(0);
  const [projectsPerPage, setProjectsPerPage] = useState(2);
  const [isCarouselActive, setIsCarouselActive] = useState(false);

  useEffect(() => {
    const mediaQuery1199 = window.matchMedia("(max-width: 1199px)");
    const mediaQuery991 = window.matchMedia("(max-width: 991px)");
    const handleMediaQueryChange = () => {
      setIsCarouselActive(mediaQuery1199.matches);
      setProjectsPerPage(mediaQuery991.matches ? 1 : 2);
    };

    handleMediaQueryChange();
    mediaQuery1199.addEventListener('change', handleMediaQueryChange);
    mediaQuery991.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery1199.removeEventListener('change', handleMediaQueryChange);
      mediaQuery991.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    if (section ===  2 ) {
      setLoading(true);
    } else if (section === 3) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000); 
      return () => clearTimeout(timer);
    }
  }, [section]);


  const handleTabChange = (newTab) => {
    setTransitionStage('fadeOut');
    setTimeout(() => {
      setActiveTab(newTab);
      setCurrentPage(0); 
      setTransitionStage('fadeIn');
    }, 500);
  };

  const projects = activeTab === 'Tab1' ? projects1 : projects2;
  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const renderProjects = () => {
    if (isCarouselActive) {
      // Carousel logic
      const startIndex = currentPage * projectsPerPage;
      const selectedProjects = projects.slice(startIndex, startIndex + projectsPerPage);

      return (
        <>
          <div className={`tab-content ${transitionStage}`}>
            <div className={`projects__wrapper ${loading ? 'projects__wrapper-start' : 'projects__wrapper-end'}`}>
              <button className="arrow left" onClick={prevPage}>
                <span className={activeTab === 'Tab1' ? "text_pink" : "text_blue"}>&lt;</span>
              </button>
              {selectedProjects.map((project, index) => (
                <ProjectCard
                  key={index}
                  project={project}
                  activeTab={activeTab}
                  section={section}
                />
              ))}
              <button className="arrow right" onClick={nextPage}>
                <span className={activeTab === 'Tab1' ? "text_pink" : "text_blue"}>&gt;</span>
              </button>
            </div>
          </div>

          <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                className={activeTab === 'Tab1' ? "" : "blue-box"}
                style={{ backgroundColor: i === currentPage ? 'white' : 'rgba(1, 1, 1, 0)' }}
                onClick={() => setCurrentPage(i)}
              >
              </button>
            ))}
          </div>
        </>
      );
    } else {
      // Full grid display logic for large screens
      return (
        <div className={`tab-content ${transitionStage}`}>
          <div className={`projects__wrapper ${loading ? 'projects__wrapper-start' : 'projects__wrapper-end'}`}>
            {projects.map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                activeTab={activeTab}
                section={section}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className='projects__tab-component'>
      <div className="projects__button-wrapper">
        <button
          className={activeTab === 'Tab1' ? "projects__tab-button_active1 projects__tab-button projects__tab-button1 block block_projects" : "projects__tab-button projects__tab-button1 block block_projects"}
          onClick={() => handleTabChange('Tab1')}
        >
          With Links
        </button>
        <button
          className={activeTab === 'Tab2' ? "projects__tab-button_active2 projects__tab-button projects__tab-button2 block block_projects" : "projects__tab-button projects__tab-button2 block block_projects"}
          onClick={() => handleTabChange('Tab2')}
        >
          Only GitHub
        </button>
      </div>

      {renderProjects()}
    </div>
  );
};


const ProjectCard = ({project, activeTab, section}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // console.log(`ProjectCard section received:`, section); // Log the received section
    if (section === undefined) {
      // console.warn('ProjectCard component did not receive section prop. Check parent component for prop passing.');
      return;
    }

    let timer;
    if (section === 3) {
      timer = setTimeout(() => {
        setIsVisible(true);
      }, 1700); // Set visibility to true after 2 seconds only when section equals 3
      // console.log('Timer set for section 3');
    }

    return () => {
      if (timer) {
        clearTimeout(timer); // Clear the timeout if the component unmounts
        // console.log('Timer cleared');
      }
    };
  }, [section]);

  return (
    <Tilt options={defaultOptions} className="projects__card">
      {/* <div className="projects__card-img-holder"> */}
      <div className={`projects__card-img-holder ${isVisible ? 'fade-in visible' : 'fade-in'}`}>
        <img src={project.img} alt={project.title} className='projects__card-img'/>

        <div className="projects__wrapper-for-links">
          {project.link && (
            <a 
              className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_internet" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_internet"} 
              href={project.link}
            >
              <img src={internetIcon} alt="internet" />
            </a>
          )}

          <a 
            className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_github" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_github"} 
            href={project.linkGitHub}
          >
            <img src={ activeTab === 'Tab1' ? githubIcon : githubIconBlue} alt="github" />
          </a>
        </div>
      </div>

      <div className={`${isVisible ? 'fade-in visible' : 'fade-in'}`}>
        <h3 className={activeTab === 'Tab1' ? "projects__card__header1" : "projects__card__header2"}>{project.title}</h3>
        <p className='fz-text'>{project.description}</p>
        <span>
          {project.hashtags.map((hashtag, index) => (
            <div className={`hashtag_${index}`} key={index}>{hashtag}</div>
          ))}
        </span>
      </div>
    </Tilt>
  );
};

export default ProjectsSection
