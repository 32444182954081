import { useState, useRef, useMemo, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import { MathUtils } from 'three';
import { useSection } from "./SectionContext";

function JsIconCube({ section, img, modelPath, position }) {
  const gltf = useGLTF(modelPath);
  const scene = useMemo(() => gltf.scene.clone(), [gltf.scene]);
  const texture = useTexture(img);
  const groupRef = useRef();
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [defaultRotationY, setDefaultRotationY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const {screenWidth} = useSection();

  const onPointerOver = () => {
    document.body.style.cursor = 'pointer';
  };

  const onPointerOut = () => {
    document.body.style.cursor = 'default';
  };

  const bind = useMemo(() => ({
    onPointerDown: ({ clientX, clientY }) => {
      if (screenWidth >= 1000) { 
        setIsDown(true);
        setStartX(clientX);
        setStartY(clientY);
      }
    },
    // onPointerUp: () => setIsDown(false),
    onPointerMove: ({ clientX, clientY }) => {
      if (isDown && screenWidth >= 1000) {
        const dx = (clientX - startX) * 0.03;  // Sensitivity adjust
        const dy = (clientY - startY) * 0.03;  // Sensitivity adjust
        const newRotationX = rotationX + dx;
        const newRotationY = rotationY + dy;
        setRotationX(newRotationX);
        setRotationY(newRotationY);
        groupRef.current.rotation.y = newRotationX;
        groupRef.current.rotation.x = newRotationY;
      }
    },
    onPointerUp: () => {
      if (screenWidth >= 1000) {
        setIsDown(false);
      }
    },
  }), [isDown, startX, startY, rotationX, rotationY]);

  useFrame(() => {
    if (!isVisible || !groupRef.current) return; 
    
    if (!isDown) {
      // Apply the default slow rotation along the y-axis when not dragging
      setDefaultRotationY(prev => prev + 0.01); // Speed adjust needed
      groupRef.current.rotation.y = defaultRotationY;

      // Smoothly return to the default position after dragging
      groupRef.current.rotation.x = MathUtils.lerp(groupRef.current.rotation.x, 0, 0.05);
    } else {
      // When dragging, override the default rotation
      groupRef.current.rotation.x = rotationY;
      groupRef.current.rotation.y = rotationX;
    }
  });

  useEffect(() => {
    const updateVisibility = () => {
      const visible = section !== 3;
      setIsVisible(visible);
    };

    const timer = setTimeout(updateVisibility, 1000); // Delayed visibility

    return () => clearTimeout(timer);
  }, [section]);

  if (!isVisible) {
    return null; 
  }

  return (
    <group ref={groupRef} position={position} {...bind}
      onPointerOver={onPointerOver} 
      onPointerOut={onPointerOut}
    >
      <primitive 
        object={scene} 
        position={[0, -0.35, 0]} 
        rotation={[0, Math.PI / 2, 0]} 
        scale={[0.4, 0.4, 0.4]} 
      />
      <mesh position={[0, 0, 0]} scale={[0.4, 0.4, 0.4]} > 
        <planeGeometry args={[0.7, 0.7]} />
        <meshBasicMaterial map={texture} />
      </mesh>
      <mesh position={[0, 0, 0]} rotation={[0, Math.PI, 0]} scale={[0.4, 0.4, 0.4]} > 
        <planeGeometry args={[0.7, 0.7]} /> 
        <meshBasicMaterial map={texture} />
      </mesh>
    </group>
  );
}

export default JsIconCube;