import React from 'react';
import { motion } from 'framer-motion';

export const Section = (props) => {
  const {children, id} = props;

  return (
    <motion.section 
      id={id}
      className={`interface__section ${id}`}
      initial={{
        opacity: 0,
        y: 50,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 1,
          delay: 0.6,
        }
      }}
    >
      {children}
    </motion.section>
  )
} 