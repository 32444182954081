import { useRef, useEffect, useState } from "react";
import { useScroll } from "@react-three/drei";
import { gsap } from "gsap";
import { useSection } from "./SectionContext";

export const ScrollManager = () => {
  const { section, setSection } = useSection();
  const data = useScroll();
  const isAnimating = useRef(false);
  const touchStartY = useRef(0);
  const [scrollCooldown, setScrollCooldown] = useState(false);

  if (data.fill) {
    data.fill.classList.add("scroll-fix");
  }

  const isInsideScrollable = (target) => {
    return target.closest('.scrollable-text') !== null;
  };

  useEffect(() => {
    data.el.style.overflow = isAnimating.current ? "hidden" : "auto";

    gsap.to(data.el, {
      duration: 1,
      scrollTop: section * data.el.clientHeight,
      onStart: () => {
        isAnimating.current = true;
        data.el.style.overflow = "hidden";
      },
      onComplete: () => {
        isAnimating.current = false;
        data.el.style.overflow = "auto";
      }
    });
  }, [section, data.el, data]);

  useEffect(() => {
    const handleWheelScroll = (e) => {
      e.preventDefault();

      if (!isAnimating.current && !scrollCooldown) {
        if (e.deltaY < 0 && section > 0) {
          setScrollCooldown(true);
          setSection(prev => Math.max(0, prev - 1));
        } else if (e.deltaY > 0 && section < data.pages - 1) {
          setScrollCooldown(true);
          setSection(prev => Math.min(data.pages - 1, prev + 1));
        }
        setTimeout(() => {
          setScrollCooldown(false);
        }, 1500);
      }
    };

    const handleTouchStart = (e) => {
      if (window.innerWidth < 1100 && !isInsideScrollable(e.target)) {
        touchStartY.current = e.touches[0].clientY;
      }
    };

    const handleTouchEnd = (e) => {
      if (window.innerWidth < 1100 && !isInsideScrollable(e.target)) {
        const touchEndY = e.changedTouches[0].clientY;
        const touchDifference = touchStartY.current - touchEndY;

        if (!isAnimating.current && !scrollCooldown && Math.abs(touchDifference) > 50) { // Threshold for swipe detection
          if (touchDifference > 0 && section < data.pages - 1) {
            // Swiping up
            setScrollCooldown(true);
            setSection(prev => Math.min(data.pages - 1, prev + 1));
          } else if (touchDifference < 0 && section > 0) {
            // Swiping down
            setScrollCooldown(true);
            setSection(prev => Math.max(0, prev - 1));
          }
          setTimeout(() => {
            setScrollCooldown(false);
          }, 1500);
        }
      }
    };

    data.el.addEventListener('wheel', handleWheelScroll);
    data.el.addEventListener('touchstart', handleTouchStart);
    data.el.addEventListener('touchend', handleTouchEnd);

    return () => {
      data.el.removeEventListener('wheel', handleWheelScroll);
      data.el.removeEventListener('touchstart', handleTouchStart);
      data.el.removeEventListener('touchend', handleTouchEnd);
    };
  }, [section, data.el, data.pages, setSection, isAnimating, scrollCooldown]);

  return null;
};












// import { useRef, useEffect, useState } from "react";
// import { useScroll } from "@react-three/drei";
// import { gsap } from "gsap";
// import { useSection } from "./SectionContext";

// export const ScrollManager = () => {
//   const { section, setSection } = useSection();
//   const data = useScroll();
//   const isAnimating = useRef(false);
//   const touchStartY = useRef(0);
//   const [scrollCooldown, setScrollCooldown] = useState(false);

//   if (data.fill) {
//     data.fill.classList.add("scroll-fix");
//   }

//   const isInsideScrollable = (target) => {
//     return target.closest('.scrollable-text') !== null;
//   };

//   useEffect(() => {
//     data.el.style.overflow = isAnimating.current ? "hidden" : "auto";

//     gsap.to(data.el, {
//       duration: 1,
//       scrollTop: section * data.el.clientHeight,
//       onStart: () => {
//         isAnimating.current = true;
//         data.el.style.overflow = "hidden";
//       },
//       onComplete: () => {
//         isAnimating.current = false;
//         data.el.style.overflow = "auto";
//       }
//     });
//   }, [section, data.el, data]);

//   useEffect(() => {
//     const handleWheelScroll = (e) => {
//       e.preventDefault();

//       if (!isAnimating.current && !scrollCooldown) {
//         if (e.deltaY < 0 && section > 0) {
//           setScrollCooldown(true);
//           setSection(prev => Math.max(0, prev - 1));
//         } else if (e.deltaY > 0 && section < data.pages - 1) {
//           setScrollCooldown(true);
//           setSection(prev => Math.min(data.pages - 1, prev + 1));
//         }
//         setTimeout(() => {
//           setScrollCooldown(false);
//         }, 1500);
//       }
//     };

//     const handleTouchStart = (e) => {
//       if (window.innerWidth < 1100 && !isInsideScrollable(e.target)) {
//         touchStartY.current = e.touches[0].clientY;
//       }
//     };

//     const handleTouchEnd = (e) => {
//       if (window.innerWidth < 1100 && !isInsideScrollable(e.target)) {
//         const touchEndY = e.changedTouches[0].clientY;
//         const touchDifference = touchStartY.current - touchEndY;

//         if (!isAnimating.current && !scrollCooldown && Math.abs(touchDifference) > 50) { // Threshold for swipe detection
//           if (touchDifference > 0 && section < data.pages - 1) {
//             // Swiping up
//             setScrollCooldown(true);
//             setSection(prev => Math.min(data.pages - 1, prev + 1));
//           } else if (touchDifference < 0 && section > 0) {
//             // Swiping down
//             setScrollCooldown(true);
//             setSection(prev => Math.max(0, prev - 1));
//           }
//           setTimeout(() => {
//             setScrollCooldown(false);
//           }, 1500);
//         }
//       }
//     };

//     data.el.addEventListener('wheel', handleWheelScroll);
//     data.el.addEventListener('touchstart', handleTouchStart);
//     data.el.addEventListener('touchend', handleTouchEnd);

//     return () => {
//       data.el.removeEventListener('wheel', handleWheelScroll);
//       data.el.removeEventListener('touchstart', handleTouchStart);
//       data.el.removeEventListener('touchend', handleTouchEnd);
//     };
//   }, [section, data.el, data.pages, setSection, isAnimating, scrollCooldown]);

//   return null;
// };








// import { useRef, useEffect, useState } from "react";
// import { useScroll } from "@react-three/drei";
// import { gsap } from "gsap";
// import { useSection } from "./SectionContext";

// export const ScrollManager = () => {
//   const { section, setSection } = useSection();  
//   const data = useScroll();
//   const isAnimating = useRef(false);
//   const [scrollCooldown, setScrollCooldown] = useState(false);

//   if (data.fill) {
//     data.fill.classList.add("scroll-fix");
//   }

//   useEffect(() => {
//     data.el.style.overflow = isAnimating.current ? "hidden" : "auto";

//     gsap.to(data.el, {
//       duration: 1,
//       scrollTop: section * data.el.clientHeight,
//       onStart: () => {
//         isAnimating.current = true;
//         data.el.style.overflow = "hidden";  // Disable scrolling while animating
//       },
//       onComplete: () => {
//         isAnimating.current = false;
//         data.el.style.overflow = "auto";  // Enable scrolling after animation ends
//       }
//     });
//   }, [section, data.el, data]);

//   useEffect(() => {
//     const handleWheelScroll = (e) => {
//       e.preventDefault()
      
//       if (!isAnimating.current && !scrollCooldown) {
//         if (e.deltaY < 0 && section > 0) {
//           // Scrolling up
//           setScrollCooldown(true);
//           setSection(prev => Math.max(0, prev - 1));
//         } else if (e.deltaY > 0 && section < data.pages - 1) {
//           // Scrolling down
//           setScrollCooldown(true);
//           setSection(prev => Math.min(data.pages - 1, prev + 1));
//         }
//         setTimeout(() => {
//           setScrollCooldown(false);
//         }, 1500);
//       }
//     };

//     data.el.addEventListener('wheel', handleWheelScroll);

//     return () => {
//       data.el.removeEventListener('wheel', handleWheelScroll);
//     };
//   }, [section, data.el, data.pages, setSection, isAnimating, scrollCooldown]);

//   return null;
// };
