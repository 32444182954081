import { useRef } from "react";
import emailjs from '@emailjs/browser';
import { Section } from "./section";
import myImg from '../../assets/photos/myimg.jpg';
import myImgMobile from '../../assets/photos/myimg_mobile.jpg'
import socialGithub from '../../assets/socialIcons/github.svg';
import socialLinkedIn from '../../assets/socialIcons/linkedin.svg';
import socialInstagram from '../../assets/socialIcons/instagram.svg';

const ContactSection = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_r0i0tuw', 'template_42o2tjl', form.current, 'zGgY2p7IOzkh8VEwU')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <Section id="connect__section">
      <div id="connect" className="connect ">
        <div className="connect__wrapper ">
          <div className="connect__imgarea">
            <img className="connect__imgarea-img" src={myImg} alt="aboutme-img" />
            {/* <img className="connect__imgarea-img-mobile" src={myImgMobile} alt="aboutme-img" /> */}
          </div>
            
          <div className="connect__textarea">
            <div className="connect__textarea-wrapper">
              <div className="connect__textarea-header">
                <div>
                  <img className="connect__imgarea-img-mobile-small" src={myImgMobile} alt="aboutme-img" />
                </div>

                <div>
                  <div className="section__text section__text_normal">Contacts_</div>
                  <div className="connect__divider block"></div>
                
                  <div className="connect__links">
                    <a href="https://github.com/anackis?tab=overview&from=2022-11-01&to=2022-11-11"><img src={socialGithub} alt="navIcon" className='social-icon'/></a>
                    <a href="https://www.linkedin.com/in/alex-anackis-9831371a8/"><img src={socialLinkedIn} alt="navIcon"  className='social-icon'/></a>
                    <a href="https://www.instagram.com/alex_anackis_/"><img src={socialInstagram} alt="navIcon"  className='social-icon'/></a>
                  </div>
                </div>
              </div>
              
              <form ref={form} onSubmit={sendEmail} className="form" action="#">
                <div className="form__inputs">
                  <div className="form-name form__box block block_contacts block_contacts_50 form__inputs_item">
                    <div className="focus-container">
                      <input className="form__box-textarea" autoComplete="off" required name="name" id="name" type="text" placeholder="Name"/>
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="form-email form__box block block_contacts block_contacts_50 form__inputs_item">
                    <div className="focus-container">
                      <input className="form__box-textarea" autoComplete="off" required name="email" id="email" type="email" placeholder="Email"/>
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                </div>
                
                <div className="form-text form__box block block_contacts">
                  <div className="focus-container">
                    <textarea className="form__box-textarea" name="text" id="text" placeholder="Send me a message :)"></textarea>
                    <label htmlFor="text">Your message</label>
                  </div>
                </div>
                
                <div className="form__policy ">
                  <button type="submit" className="button block block_contacts">Send a message</button> 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="area">
        <ul className="circles">
          <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
      </div>
    </Section>
  )
}

export default ContactSection
