import * as images from '../projectsImg/ProjectImg';

export const projects1 = [
  { 
    img: images.foodApp,
    link: "http://test8.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Food_App_Form_Scratch/tree/main",
    title: 'Food App', 
    description: 'This app is mix of Instagram about food and app for calculating calories / creating diet.', 
    hashtags: ['#FromScratch', '#React', '#Firebase'] 
  },
  { 
    img: images.bank,
    link: "http://www.test.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Web_Bank_App_From_Scratch",
    title: 'Web Bank', 
    description: 'Web bank where users can add funds to balance and send funds to other bank users.', 
    hashtags: ['#FromScratch', '#React', '#Firebase',] 
  },
  { 
    img: images.webShop,
    link: "http://test4.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/WebShop",
    title: 'Web Shop', 
    description: 'This is web shop with catalog of items, account creation and skrill payments.', 
    hashtags: ['#React', '#Redux', '#Firebase']  
  },
  { 
    img: images.marvel,
    link: "http://test2.alexanackis.com/#",
    linkGitHub: "https://github.com/anackis/Marvel-Wiki",
    title: 'Marvel', 
    description: 'This is Marvell wiki with connection to Marvel Api for searching Marvell characters information.', 
    hashtags: ['#React', '#UsingApi', '#FSM'] 
  },
  { 
    img: images.run,
    link: "http://test1.alexanackis.com/#promo",
    linkGitHub: "https://github.com/anackis/ProjectNr2",
    title: 'Run Smart', 
    description: 'Webpage for selling sport gear. Have features as sliders, form sending and Google Map Api.', 
    hashtags: ['#JavaScript', '#Using Api', '#Gulp'] 
  },
  { 
    img: images.productList,
    link: "http://test7.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Products-List_and_Add-Product-Panel",
    title: 'Product List', 
    description: 'This is junior recruiting task. Products list with admin panel.', 
    hashtags: ['#React', '#PHP', '#MySql'] 
  },
  { 
    img: images.pineapple,
    link: "http://test3.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Junior-Mid-recruiting-task",
    title: 'Pineapple', 
    description: 'This is junior recruiting task. Form page with validation.', 
    hashtags: ['#React', '#PixelPerfect', '#Formik+Yup'] 
  },
  { 
    img: images.losangelesMountains,
    link: "http://test5.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Losangeles_mountains-Junior-Recruiting_Test-",
    title: 'L.A. Mountains', 
    description: 'This is junior recruiting task. Landing page about Los Angeles Mountains.', 
    hashtags: ['#React', '#ReactHooks', '#Sass'] 
  },
];

export const projects2 = [
  { 
    img: images.portfolio,
    linkGitHub: "https://github.com/anackis/Portfolio",
    title: 'Portfolio', 
    description: 'Old Portfolio on React with adaptive design, sliders and email send form.', 
    hashtags: ['#React', '#EmailJs', '#Responsive'] 
  },
  { 
    img: images.monstaers,
    linkGitHub: 'https://github.com/anackis/Monster-Search',
    title: 'Monsters Search',
    description: 'Search project for practicing TypeScript.',
    hashtags: ['#React', '#TypeScript'] 
  },
  { 
    img: images.heroes,
    linkGitHub: 'https://github.com/anackis/Heroes-Creator-Filter',
    title: 'Heroes Creator',
    description: 'Creation form with filter for practicing Redux and Json Server.',
    hashtags: ['#React','#Redux', '#JsonServer'] 
  },
  { 
    img: images.blog,
    linkGitHub: 'https://github.com/anackis/Project_1.13_Blog_V2',
    title: 'Web Blog',
    description: 'Blog project with post creation for practicing EJS and MongoDb.',
    hashtags: ['#EJS', '#MongoDb'] 
  },
  { 
    img: images.adminPanel,
    linkGitHub: 'https://github.com/anackis/Workers-Admin-Panel',
    title: 'Admin Panel',
    description: 'Admin panel for workers management for practicing React Hooks and Components.',
    hashtags: ['#React', '#ReactHooks/Components'] 
  },
  { 
    img: images.food,
    linkGitHub: 'https://github.com/anackis/Food-Store',
    title: 'Web Food',
    description: 'Beautiful website about food with calories calculator built with Javascript.',
    hashtags: ['#JavaScript', '#JSON'] 
  },
  { 
    img: images.todolist,
    linkGitHub: 'https://github.com/anackis/Project_1.12_ToDoList-V2-MongoDB-',
    title: 'To Do List',
    description: 'Simple To Do List for EJS and MongoDb practicing.',
    hashtags: ['#EJS', '#MongoDb'] 
  },
  { 
    img: images.form,
    linkGitHub: 'https://github.com/anackis/Small-Form-Example-',
    title: 'Form',
    description: 'Form for practicing Formik, Yup and own validation.',
    hashtags: ['#Formik', '#Yup ', '#OwnValidation'] 
  },
];