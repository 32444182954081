import { Section } from './section';

const HomeSection = () => {
  return (
    <Section id="home__section" className="home">
      <div className="home__wrapper">
        <div className="section__text section__text_main home__title">HI <span style={{ marginRight: '25px' }}></span> I AM_</div>
        <div className="container home__text-wrapper">
          <div className="glitch" data-text="ALEX ANACKIS">ALEX ANACKIS</div>
        </div>
        <div className="section__text section__text_main section__text_bottom home__title">I AM_</div>
        <div className="container container_bottom home__text-wrapper">
          <div className="glitch" data-text="WEB DEVELOPER">WEB DEVELOPER</div>
        </div>
      </div>
    </Section>
  )
}

export default HomeSection;
