import { Section } from './section';

const SkillsSection = () => {
  return (
    <Section id="skills__section">
      <div className="about block">
        <div className="bubble_wrap section__header">
          <div className="section__text section__text_normal fz-header">Skills_</div>
        </div>
        <div className="bubble_wrap">
          <p className='fz-text scrollable-text'>
          During my progress way I  learned, acquired, developed many skills, and got familiar 
          with many technologies. One of the most important skills for junior Web Developers 
          I learned I think are reading documentations, having the ability to understand new 
          technologies knowing the bases, searching for solutions on the internet, and trying 
          to develop skills as much as possible even from failures and mistakes. 
          <br/><br/>
          The good level base skills that I acquired and developed are HTML, CSSS 
          (SASS/SCSS), JS, React, React Hooks, and Redux. As well, I have got familiar 
          with many technologies such as THREE.js BEM, Bootstrap, Git, GitHub, GitKraken, JQuery, 
          Node.js, Gulp, TypeScript, Express, APIs,  RestAPI, MongoDB, Figma, Avocode, Asana, 
          Firebase, hosting projects with FileZilla, Heroku and MongoDB Atlas, and a huge 
          amount of JS and React libraries.
          </p>
        </div>
      </div>

      <div className="area">
        <ul className="circles">
          <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
      </div>
    </Section>
  )
}

export default SkillsSection
