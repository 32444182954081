import { useEffect, useRef } from 'react'
import { useAnimations, useFBX, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
// import { useControls } from 'leva';
import * as THREE from "three";
import { useSection } from './SectionContext';
// import { throttle } from 'lodash';
// import { Vector3 } from "three";

export function Avatar(props) {
  const { section, screenWidth } = useSection();
  const {animation} = props;
  // const {headFollow, cursorFollow, wireframe} = useControls({
  //   headFollow: false,
  //   cursorFollow: true,
  //   // wireframe: false,
  // })
  const group = useRef();
  const { nodes, materials } = useGLTF('models/avatar.glb');

  const { animations: fallingAnimation } = useFBX("animations/falling.fbx");
  const { animations: helloAnimation } = useFBX("animations/hello.fbx");
  fallingAnimation[0].name = "Falling"
  helloAnimation[0].name = "Hello"
  
  const { actions } = useAnimations([fallingAnimation[0], helloAnimation[0]], group);


  // const updateLookAt = (state) => {
  //   const target = new THREE.Vector3(state.mouse.x, state.mouse.y, 1);
  //   group.current.getObjectByName("Spine2").lookAt(target);
  // };
  // const throttledUpdate = throttle(updateLookAt, 100);

  // useFrame((state) => {
  //   throttledUpdate(state)
  // })

  // let lastMousePosition = new THREE.Vector2(0, 0);
  // let lastUpdate = Date.now();

  // let currentLookTarget = new Vector3();
  // let targetLookTarget = new Vector3();

  // useFrame((state) => {

  //   // const mouseDelta = lastMousePosition.distanceTo(new THREE.Vector2(state.mouse.x, state.mouse.y));
  //   // if (mouseDelta > 0.0005) { // Replace SOME_THRESHOLD with a value that works for you, e.g., 0.1
  //   //   lastMousePosition.set(state.mouse.x, state.mouse.y);
  //   //   updateLookAt(state);
  //   // }
    
  //   const target = new THREE.Vector3(state.mouse.x, state.mouse.y, 1);
  //   group.current.getObjectByName("Spine2").lookAt(target);
  // });

  useFrame((state) => {
    let target = new THREE.Vector3(state.mouse.x, state.mouse.y, 1);
  
    if (screenWidth < 650 && section === 1) {
      target.set(-0.18, -0.5, 1);
    } else if (screenWidth < 650) {
      target.set(0.3, -0.2, 1);
    } else if (screenWidth < 1200) {
      target.set(0.7833333333333334, 0.5575757575757576, 1);
    }
    
    group.current.getObjectByName("Spine2").lookAt(target);
  });
  

  // useFrame((state) => {
  //   // Set target look direction based on cursor position
  //   targetLookTarget.set(state.mouse.x, state.mouse.y, 1);
  
  //   // Smoothly interpolate current look target towards the target look direction
  //   currentLookTarget.lerp(targetLookTarget, 0.1); // Adjust the lerp factor (0.1) for smoother or faster transition
  
  //   // Update avatar look direction
  //   if (group.current) {
  //     const spine = group.current.getObjectByName("Spine2");
  //     if (spine) {
  //       spine.lookAt(currentLookTarget);
  //     }
  //   }
  // });

  useEffect(() => {
    // actions["Falling"].reset().play();
    actions[animation].reset().fadeIn(0.6).play();
    
    return () => {
      actions[animation].reset().fadeOut(0.5);
    }
  }, [animation, ]);

  // useEffect(() => {
  //   Object.values(materials).forEach((material) => {
  //     material.wireframe = wireframe
  //   });
  // }, [wireframe]);

  return (
    <group  {...props} ref={group} dispose={null}>
      <group name="Armature" rotation-x={-Math.PI / 1.8 }>
        <primitive object={nodes.Hips} />
        <skinnedMesh
          geometry={nodes.Wolf3D_Body.geometry} 
          material={materials.Wolf3D_Body} 
          skeleton={nodes.Wolf3D_Body.skeleton} 
        />
        <skinnedMesh 
          geometry={nodes.Wolf3D_Outfit_Bottom.geometry} 
          material={materials.Wolf3D_Outfit_Bottom} 
          skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton} 
        />
        <skinnedMesh 
          geometry={nodes.Wolf3D_Outfit_Footwear.geometry} 
          material={materials.Wolf3D_Outfit_Footwear} 
          skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton} 
        />
        <skinnedMesh 
          geometry={nodes.Wolf3D_Outfit_Top.geometry} 
          material={materials.Wolf3D_Outfit_Top} 
          skeleton={nodes.Wolf3D_Outfit_Top.skeleton} 
        />
        <skinnedMesh 
          geometry={nodes.Wolf3D_Hair.geometry} 
          material={materials.Wolf3D_Hair} 
          skeleton={nodes.Wolf3D_Hair.skeleton} 
        />
        <skinnedMesh 
          name="EyeLeft" 
          geometry={nodes.EyeLeft.geometry} 
          material={materials.Wolf3D_Eye} 
          skeleton={nodes.EyeLeft.skeleton} 
          morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary} 
          morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences} 
        />
        <skinnedMesh 
          name="EyeRight" 
          geometry={nodes.EyeRight.geometry} 
          material={materials.Wolf3D_Eye} 
          skeleton={nodes.EyeRight.skeleton} 
          morphTargetDictionary={nodes.EyeRight.morphTargetDictionary} 
          morphTargetInfluences={nodes.EyeRight.morphTargetInfluences} 
        />
        <skinnedMesh 
          name="Wolf3D_Head" 
          geometry={nodes.Wolf3D_Head.geometry} 
          material={materials.Wolf3D_Skin} 
          skeleton={nodes.Wolf3D_Head.skeleton} 
          morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary} 
          morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences} 
        />
        <skinnedMesh 
          name="Wolf3D_Teeth" 
          geometry={nodes.Wolf3D_Teeth.geometry} 
          material={materials.Wolf3D_Teeth} 
          skeleton={nodes.Wolf3D_Teeth.skeleton} 
          morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary} 
          morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences} 
        />
      </group>
    </group>
  )
}

useGLTF.preload('models/avatar.glb')
