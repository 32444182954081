import { Section } from './section';

const AboutSection = () => {
  return (
    <Section id="about__section">
      <div className="about block">
        <div className="bubble_wrap section__header">
          <div className="section__text section__text_normal fz-header">A little bit about me_</div>
        </div>
        <div className="bubble_wrap">
          <p className='fz-text scrollable-text'>
            My name is Alex. I am 24 years highly motivated and energetic junior Web Developer. I am acquiring web development skills for 9+ months. I built over 35 different levels of difficulty projects that are public on my <a className='fz-text'  href="https://github.com/anackis?tab=repositories">GitHub</a> page. Previously I was working almost 3 years at It company Comino as Test Engineer but I decided to switch my profession to Web Developer.
            <br/><br/>
            I finished 4 online courses on the Udemy platform, was developing my skills at Free-code camp and other platforms for leveling up skills. During my progress way I learned, acquired, developed many skills, and got familiar with many technologies.
            <br/><br/>
            Last my working experience was working in an IT company for over 3 years where I have got skills of effectively self-managing during independent projects, as well as collaborating as part of a productive team.
            <br/><br/>
            I like sports, driving my car, and playing PC games with my friends. I like going to the cinema and especially Marvel movies and series. As well I like anime and one of my most lovely is Cyberpunk Edgerunners.
          </p>
        </div>
      </div>
    </Section>
  )
}

export default AboutSection