import HomeSection from './page-components/home-section';
import AboutSection from './page-components/about-section';
import SkillsSection from './page-components/skills-section';
import ProjectsSection from './page-components/projects-section';
import ContactSection from './page-components/contact-section';

export const Interface = ({ currentSection, screenWidth }) => {
  return (
    <div className='interface-section'>
      <HomeSection />
      <AboutSection />
      <SkillsSection />
      <ProjectsSection currentSection={currentSection} screenWidth={screenWidth}/>
      <ContactSection />
    </div>
  );
}
