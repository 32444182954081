import { Avatar } from "./Avatar";
import { Room } from "./Room";
import { motion } from "framer-motion-3d";
import { Environment, useScroll } from "@react-three/drei";
import { animate, useMotionValue } from "framer-motion";
import { useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { framerMotionConfig } from "../assets/config";

import JsIconCube from "./Prisms";
import EarthModel from './EarthModel';

import JsIcon from "../assets/icons/js.png";
import HtmlIcon from '../assets/icons/html.svg';
import CssIcon from '../assets/icons/css.svg';
import SassIcon from '../assets/icons/sass.svg';
import ReactIcon from '../assets/icons/react.webp';
import ReduxIcon from '../assets/icons/redux.svg';
import GitIcon from '../assets/icons/git.svg';
import TsIcon from '../assets/icons/ts.png';
import NodeJsIcon from '../assets/icons/nodeJs.png';

import { useSection } from "./SectionContext";


export const Experience = () => {
  const cameraPositionX = useMotionValue(0);
  const cameraLookAtX = useMotionValue(0); 
  // const data = useScroll();
  // const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
  const { section, setSection, menuOpened, screenWidth } = useSection();
  const data = useScroll();

  useEffect(() => {
    animate(cameraPositionX, menuOpened ? -3 : 0, {
      ...framerMotionConfig,
    });
    animate(cameraLookAtX, menuOpened ? 0 : 0, {
      ...framerMotionConfig,
    });
  }, [menuOpened]);

  // Mneu Open 3D objects animation
  useFrame((state) => {

    // if (screenWidth < 1200 ) {
    //   const curSection = Math.floor(data.scroll.current * data.pages) /// OLD VERSION
    //   if (curSection !== section) {
    //     setSection(curSection);
    //   }
    // }
    

    // const curSection = section;   // Check this! Looks like helps to avoid on page reload laggy avatar animation switch from section 0 to section 1 
    // console.log(`Experience useFrame triggered. curSection=${curSection}/ section=${section}`);
    // if (curSection !== section) {
    //   setSection(curSection);
    //   console.log(`Experience useFrame changing. curSection=${curSection}/ section=${section}`);
    // }

    state.camera.position.x = cameraPositionX.get();
    state.camera.lookAt(cameraLookAtX.get(), 0, 0);
  });

  let jsIconsPositions = [
    [0.1, 0.8, 0],
    [1, 0.8, 0],
    [1.9, 0.8, 0],

    [0.1, -0.10, 0.1],
    [1, -0.10, 0.1],
    [1.92, -0.10, 0.1],

    [0.1, -0.95, 0.3],
    [1.02, -0.95, 0.3],
    [1.95, -0.95, 0.3],
  ];
  const images = [JsIcon, HtmlIcon, CssIcon, ReactIcon, TsIcon, SassIcon, ReduxIcon, GitIcon, NodeJsIcon];

  let motionGroupRotation = [-0.2, Math.PI / -5, 0]

  let avatarPosX = 0.2; 
  let avatarPosY = section === 0 ? 0 : 0.3;
  let avatarPosZ = -0.5;
  let avatarScale = section === 0 ? 1 : 0.55; 

  let roomPosX = 0.2; 
  let roomPosY = 0.3;
  let roomPosZ = -0.5;
  let roomScale = 1.2; 

  let jsIconsPosX = 0.3;
  let jsIconsScale = 1;
  
  let earthPositionX = 1.5;
  let earthPosX = 0;
  let earthPosY = 0;
  let earthScale = 0.8;

  if (screenWidth < 2000) {
    earthPositionX = 1.8

  } if (screenWidth < 1700) {
    jsIconsScale = 0.8;

  } if (screenWidth < 1450) {
   
    if (section === 0) {
      avatarPosX = -0.5;
    } 

  } if (screenWidth < 1200) {
    jsIconsScale = 0.55;

  } if (screenWidth < 991) {
    motionGroupRotation = [0, -0.2, -0.1]

    if (section === 0) {
      avatarPosX = -0.5;
    } else {
      avatarPosX = -0.2;
      roomPosX = 0; 
    }

    jsIconsPosX = -0.5;
    jsIconsScale = 0.5;
    jsIconsPositions = [
      [-0.4, -1.5, 0],
      [0.5, -1.5, 0],
      [1.4, -1.5, 0],
      [2.3, -1.5, 0],

      [-0.7, -2.5, 0],
      [0.2, -2.5, 0],
      [1.1, -2.5, 0],
      [2, -2.5, 0],
      [2.9, -2.5, 0],
    ];

    earthPosX = -2.8;
    earthPosY = 1.5;
    earthScale = 0.6;
    
  } if (screenWidth < 650) {
    if (section === 0) {
     
      avatarPosX = -1.1;
      avatarPosY = -0.8;
      avatarPosZ = -0.2;
      avatarScale = 0.8;
    } else {
      avatarPosX = -1;
      avatarPosY = -1.5;
      avatarPosZ = -0.2;
      avatarScale = 0.8;
  
      roomPosX = -1; 
      roomPosY = -1.5;
      roomPosZ = -0.5;
      roomScale = 2.1; 

      jsIconsScale = 0.35;
      jsIconsPosX = -0.4;
      jsIconsPositions = [
        [-0.2, -2.8, 0],
        [0.55, -2.4, 0],
        [1.35, -2.8, 0],
        [2.05, -2.4, 0],
  
        [-0.2, -3.85, 0],
        [0.55, -3.5, 0],
        [1.35, -3.85, 0],
        [2.05, -3.5, 0],
        [2.8, -3.85, 0],
      ];
    }

    earthPosX = -3.55;
    earthPosY = 2.1;
    earthScale = 0.5;
    
  } if (screenWidth < 400) {
    if (section === 0) {
      avatarPosY = -0.6;
    } else {
      avatarPosY = -1.5;
    }
  } if (screenWidth > 1500) {
    avatarPosX = 0;
  }

  return (
    <>
      <motion.group
        // rotation={[-0.2, Math.PI / -5, 0]}
        rotation={motionGroupRotation}
        animate={{
          y: section === 0 ? -1.1 : -0.9,
          x: (section === 2 || section === 3 || section === 4 || section === 5) ? 10 : 1,
        }}
        transition={{ duration: 2 }}
        position-y={-1.5}
        position-x={1}
        // position-z={-2}
      >        
        
        <Avatar
          rotation={section === 0 ? [0, 0, 0] : [0.2, 0, 0]}
          animation={section === 0 ? "Falling" : "Hello"}
          position-x={avatarPosX}
          position-y={avatarPosY}
          position-z={avatarPosZ}
          scale={avatarScale}
        />

        {(section === 1 || section === 2 ) && (
          <Room 
            rotation={[0.1, 0.7, 0]} 
            position-x={roomPosX}  
            position-y={roomPosY} 
            position-z={roomPosZ}
            scale={roomScale}
            section={section}
          />
        )}
       
      </motion.group>

      <motion.group
        // rotation={[-0.2, Math.PI / -6, 0]}
        animate={{
          y: section === 2 ? 0 : -10,
          // x: (section === 2 || section === 3 || section === 4) ? 10 : 1,
        }}
        transition={{ duration: 2 }}
        position-x={jsIconsPosX}
        // position-y={jsIconsPosY}
        scale={jsIconsScale}
      >

        { (section === 1 || section === 2 || section === 3) && (
          jsIconsPositions.map((position, index) => (
            <JsIconCube 
              key={index} 
              position={position} 
              img={images[index]} 
              modelPath="/models/prism.glb"
              section={section}
            />
          )
        ))}

      </motion.group>

      {section !== 0 && section !== 2 && section !== 1 &&(
          <motion.group
          animate={{
            x: (section === 4 || section === 5) ? earthPositionX : 11,
          }}
          transition={{ duration: 2 }}
          position-x={9}
          scale={earthScale}
        >
          <EarthModel position={[earthPosX, earthPosY, 0]} /> 
        </motion.group>
      )}

      <Environment preset="sunset" />
    </>
  );
};





// const { animation } = useControls({
//   animation: {
//     value: "Falling",
//     options: ["Falling", "Hello"],
//   },
// });

// {/* <Avatar animation={animation}/> */}
// {/* <OrbitControls />
// <Environment preset="sunset"/> */}
// {/* <Room /> */}
// {/* <ambientLight intensity={1} /> */}