import { useEffect, useState } from "react";
import { useSection } from './SectionContext';
import socialGithub from '../assets/socialIcons/github.svg';
import socialLinkedIn from '../assets/socialIcons/linkedin.svg';
import socialInstagram from '../assets/socialIcons/instagram.svg';

const useDelayedVisibility = (isVisible, delay) => {
  const [isVisibleDelayed, setIsVisibleDelayed] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        setIsVisibleDelayed(true);
      }, delay);
    } else {
      clearTimeout(timeoutId);
      setIsVisibleDelayed(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible, delay]);

  return isVisibleDelayed;
};

export const Menu = () => {
  const { section, setSection, menuOpened, setMenuOpened } = useSection();
  const delayedMenuOpen = useDelayedVisibility(menuOpened, 900);

  useEffect(() => {
    if (menuOpened) {
      setMenuOpened(false); 
    }
  }, [section]);

  return(
    <>
      <button
        className="hamburger"
        onClick={() => setMenuOpened(!menuOpened)}
      >
        <div className={`hamburger__line ${menuOpened ? "hamburger__rotate-45 hamburger__line_white" : ""}`}></div>
        <div className={`hamburger__line ${menuOpened ? "hidden" : ""}`}></div>
        <div className={`hamburger__line ${menuOpened ? "hamburger__rotate-minus-45 hamburger__line_white" : ""}`}></div>
      </button>
      <div className={`navbar ${menuOpened ? " block_menu" : "navbar__hidden block_menu"}`}>
        <div className="navbar__wrapper">
          {["HOME", "ABOUT", "SKILLS", "PROJECTS", "CONTACTS"].map((label, index) => (
            <MenuButton
              key={index} 
              label={label}
              onClick={() => setSection(index)}
              isVisible={delayedMenuOpen}
            />
          ))}
          <div className="navbar__wrapper_links">
            <a href="https://github.com/anackis?tab=overview&from=2022-11-01&to=2022-11-11">
              <img src={socialGithub} alt="navIcon" className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </a>
            <a href="https://www.linkedin.com/in/alex-anackis-9831371a8/">
              <img src={socialLinkedIn} alt="navIcon"  className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </a>
            <a href="https://www.instagram.com/alex_anackis_/">
              <img src={socialInstagram} alt="navIcon"  className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const MenuButton = ({ label, onClick, isVisible }) => {
  return (
    <button
      onClick={onClick}
      className={`navbar__link navbar__neonText ${isVisible ? " navbar__link--visible" : ""}`}
    >
      {label}
    </button>
  );
};
