import { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Scroll, ScrollControls } from "@react-three/drei";
import { MotionConfig } from "framer-motion";
import { framerMotionConfig } from "./assets/config";
import { ScrollManager } from "./components/ScrollManager";
import { Experience } from "./components/Experience";
import { Interface } from "./components/Interface";
import { Menu } from "./components/Menu";
import { useSection } from './components/SectionContext';
import LoadingScreen from "./components/page-components/loading";

function App() {
  const { section, screenWidth } = useSection();
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 3700);

  //   return () => clearTimeout(timer);
  // }, []);

  const getClassName = (section) => {
    switch (section) {
      case 0:
        return 'main main-section0';
      case 1:
        return 'main main-section1';
      default:
        return 'main';
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div id="main" className={getClassName(section)}>
      <MotionConfig transition={{ ...framerMotionConfig }}>
        <Canvas 
          // style={{ zIndex: '10' }}
          // pixelratio={Math.min(0.000000000000001, window.devicePixelRatio * 0.000000000000001)}  // pixelratio={isHighResolution ? 0.25 : window.devicePixelRatio}
          shadows={false} 
          camera={{ position: [0, 2, 5], fov: 30 }
        }>
          <ScrollControls pages={5} damping={0.1}>
            <ScrollManager/>
            <Experience 
            // style={{ position: 'relative', zIndex: '10' }}
            />
            <Scroll html>
              <Interface currentSection={section} screenWidth={screenWidth}/>
            </Scroll>
          </ScrollControls>
        </Canvas>
        <Menu/>
      </MotionConfig>
    </div>
  );
}

export default App;
